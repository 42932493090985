














































import { Component, PropSync, Watch } from 'vue-property-decorator'

import { DescriptionOfCategory, PossibleRelatedPicker, Related } from '../../../contexts'

import { RelatedPartial } from '../RelatedPartial'

/**
 * Component capable to handle Related Product Category
 *
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<CategoryForm>({ name: 'CategoryForm' })
export class CategoryForm extends RelatedPartial<DescriptionOfCategory> {
  @PropSync('category', { type: Object, required: false, default: null })
  public _category!: Related | null

  /**
   * Checks whether relatedArticle is not present
   */
  public get isEmpty (): boolean {
    return this._category === null
  }

  public get isRelated (): boolean {
    if (!this.isRelatedSet) {
      return false
    }

    return Object.prototype.hasOwnProperty.call(this._category, 'type') &&
      Object.prototype.hasOwnProperty.call(this._category, 'value')
  }

  /**
   * @inheritDoc
   */
  public get isRelatedSet (): boolean {
    return this._category !== null
  }

  /**
   * Assigns selected related to `_relatedSet`
   */
  public addRelatedTarget (): void {
    if (this.isEmpty) {
      return
    }

    this.pickRelated(PossibleRelatedPicker.Category, this.setRelated, this.getRelatedSource())
  }

  /**
   * Resets related set
   */
  public clearFilter (): void {
    this._category = null
    this.$emit('clear')
  }

  public selectRelatedFilter (): void {
    this.pickRelated(PossibleRelatedPicker.Category, this.setRelated, this.getRelatedSource())
  }

  /**
   * @inheritDoc
   * @protected
   */
  protected getRelatedSource (): Related {
    // Note! This ignore is caused by nature of computed value in Vue.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this._category
  }

  /**
   * Sets chosen set to `_relatedSet`
   * @param selected
   * @protected
   */
  protected setRelated (selected: DescriptionOfCategory): void {
    // Note! This ignore is caused by nature of computed value in Vue.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._category = selected
    this.$nextTick(() => this.describeRelated())
  }

  @Watch('described')
  private onDescribedUpdate (described: DescriptionOfCategory) {
    if (this._category) {
      this.$emit('describe', this._category.value, described)
    }
  }

  @Watch('_category')
  private onUpdate () {
    if (this.isRelatedSet) {
      this.describeRelated()
      this.resolveRelated()
    }
  }
}

export default CategoryForm
