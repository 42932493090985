






























































































import { Component, Prop, PropSync } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { v4 as uuid } from 'uuid'

import { Link, PossibleRelatedPicker, Related } from '../../../contexts'

import { RelatedPartial } from '../RelatedPartial'
import { ResolvedLink, UnresolvedLink } from './LinkForm.contracts'
import { TabOption } from '../../../dsl/abstract/components/Link/Link.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<LinkForm>({ name: 'LinkForm' })
export class LinkForm extends RelatedPartial<Link> {
  @Prop({ type: Boolean, required: false, default: true })
  public readonly canChangeType!: boolean

  @PropSync('link', { type: Object, required: false, default: null })
  public _link!: UnresolvedLink | ResolvedLink | null

  public readonly icons = DashmixIconName

  public get isEmpty (): boolean {
    return this._link === null
  }

  public get isRelatedLink (): boolean {
    return this._link !== null && 'relatedTarget' in this._link &&
      typeof this._link.relatedTarget !== 'undefined'
  }

  public get isRelatedSet (): boolean {
    return this._link !== null && 'relatedTarget' in this._link &&
      this._link.relatedTarget !== null
  }

  public get linkUUID (): string {
    return uuid().toString().substr(-6, 6)
  }

  public get newTab () {
    return this._link?.tabTarget === TabOption.Blank
  }

  public set newTab (isNewTab: boolean) {
    if (!this._link) {
      return
    }

    this._link.tabTarget = isNewTab ? TabOption.Blank : TabOption.Self
  }

  public addLink (): void {
    this._link = LinkForm.createLink()
  }

  public addRelated (): void {
    this._link = LinkForm.createRelatedLink()
  }

  public addRelatedTarget (): void {
    if (this.isEmpty || !this.isRelatedLink) {
      return
    }

    this.pickRelated(PossibleRelatedPicker.Link, this.setRelatedLink, this.getRelatedSource())
  }

  public clearLink (): void {
    this._link = null
    this.$emit('clear')
  }

  public toggleLinkType (): void {
    const preserve = {
      label: this._link?.label,
      tabTarget: this._link?.tabTarget
    }

    if (this.isRelatedLink) {
      this._link = LinkForm.createLink(preserve)
    } else {
      this._link = LinkForm.createRelatedLink(preserve)
    }
  }

  public static createLink (preserve: Partial<ResolvedLink> = {}): ResolvedLink {
    return {
      label: '',
      target: '',
      ...preserve
    }
  }

  public static createRelatedLink (preserve: Partial<UnresolvedLink> = {}): UnresolvedLink {
    return {
      label: '',
      relatedTarget: null,
      ...preserve
    }
  }

  protected getRelatedSource (): Related {
    // Note! This ignore is caused by nature of computed value in Vue.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this._link.relatedTarget
  }

  protected setRelatedLink (selected: Related): void {
    // Note! This ignore is caused by nature of computed value in Vue.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._link.relatedTarget = selected
    this.resolveRelated()
  }
}

export default LinkForm
