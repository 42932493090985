











































import { Component, Prop, PropSync } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { ContentData, PickerCallback, PossibleRelatedPicker } from '../../../../contexts'
import { SetForm } from '../../../partials/SetForm'
import { FormAddBtn } from '../../../partials/FormAddBtn'
import { Iterator } from '../../../partials/Iterator'
import { LinkForm } from '../../../partials/LinkForm'
import { RelatedPartial } from '../../../partials/RelatedPartial'
import { FormFieldset } from '../../../partials/FormFieldset'
import { ArticleForm } from '../../../partials/ArticleForm'

import { UnresolvedArticlesTeaserBoxData } from '../../ArticlesTeaserBox.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ArticlesTeaserFormItem>({
  name: 'ArticlesTeaserFormItem',
  components: { ArticleForm, LinkForm, FormAddBtn, FormFieldset, Iterator, SetForm }
})
export class ArticlesTeaserFormItem extends RelatedPartial<ContentData> {
  @Prop({ type: String, required: true })
  public readonly index!: string

  @PropSync('item', { type: Object, required: true })
  public _item!: UnresolvedArticlesTeaserBoxData

  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  public icons = DashmixIconName
  public picker = PossibleRelatedPicker

  public get isSelected (): boolean {
    return !!this._item.articlesSet || !!this._item.article
  }
}

export default ArticlesTeaserFormItem
