















import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { AllowedColor, CanHaveColor } from '../../../contexts'

import { Selectable } from '../../mixins/UseSelectable'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'ColorSchemaSelector'
})
export class ColorSchemaSelector extends Mixins<Selectable<CanHaveColor<string>>>(Selectable) {
  @Prop({ type: Object, required: false, default: () => (AllowedColor) })
  protected readonly colors!: AnyObject

  protected elementId: string = 'schema-color-selector'
  protected elementLabel: string = 'modulesForms._helpers.colorSchema.label'
  protected elementPlaceholder: string = 'modulesForms._helpers.colorSchema.placeholder'

  public get model (): CanHaveColor<string>['color'] {
    return this._content.color
  }

  public set model (newAlign: CanHaveColor<string>['color']) {
    const contentCopy = { ...this._content }

    contentCopy.color = newAlign
    this._content = contentCopy
  }

  public get options (): DashmixSelectItem[] {
    return Object.entries(this.colors).map(([name, value]) => {
      return {
        label: this.$t(`modulesForms._helpers.colorSchema.options.${name}`).toString(),
        value
      }
    })
  }
}

export default ColorSchemaSelector
