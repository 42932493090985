// Copyright © 2023 Move Closer
import { AnyObject } from '@movecloser/front-core'

import { ImageData, Module, ModuleContent, Related } from '../../contexts'

import { AvailableModuleDriver } from '../allowed'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const WhereToBuyModuleFilePickerOptions = {
  type: 'txt'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type WhereToBuyModule = Module<WhereToBuyModuleContent, AvailableModuleDriver.WhereToBuy>

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface WhereToBuyModuleContentData {
  /**
   * Determines the list of brands (ex: internet shops).
   */
  brands?: Array<{ image: ImageData; url?: string }>

  /**
   * The document file with the shops list.
   */
  shops?: Related

  /**
   * Determines the list of shops that are ALREADY resolved BY api.
   */
  shopsData?: Array<AnyObject>
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type WhereToBuyModuleContent = WhereToBuyModuleContentData & ModuleContent

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface WhereToBuyModuleContentInput extends Omit<WhereToBuyModuleContent, 'brands'> {
  /**
   * Determines the list of brands (ex: internet shops) (unresolved).
   */
  brands?: Array<{ image: Related; url?: string }>
}
