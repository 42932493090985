















































import { Component, PropSync } from 'vue-property-decorator'

import {
  DescriptionOfNavigation,
  isRelated,
  PossibleRelatedPicker,
  Related
} from '../../../contexts'

import { RelatedPartial } from '../RelatedPartial'

@Component<NavigationForm>({ name: 'NavigationForm' })
export class NavigationForm extends RelatedPartial<DescriptionOfNavigation> {
  /**
   * Related navigation
   */
  @PropSync('navigation', { type: Object, required: false, default: null })
  public _navigation!: Related | null

  /**
   * @inheritDoc
   */
  public get isRelatedSet (): boolean {
    return this._navigation !== null && isRelated(this._navigation)
  }

  /**
   * Total number of links in navigation
   */
  public get totalLinks (): number | undefined {
    if (!this.described || !this.described.links) {
      return
    }

    return this.described.links.length
  }

  /**
   * Resets related navigation
   */
  public clearNavigation (): void {
    this._navigation = null
    this.$emit('clear')
  }

  /**
   * @inheritDoc
   */
  public selectRelated (): void {
    this.pickRelated(PossibleRelatedPicker.Navigation, this.setRelated, this.getRelatedSource())
  }

  /**
   * @inheritDoc
   * @protected
   */
  protected getRelatedSource (): Related | undefined {
    if (!this.isRelatedSet || this._navigation === null) {
      return
    }
    return this._navigation
  }

  /**
   * Navigation chosen set to `_relatedSet`
   * @param selected
   * @protected
   */
  protected setRelated (selected: Related): void {
    this._navigation = selected
    this.$nextTick(() => this.describeRelated())
  }
}

export default NavigationForm
