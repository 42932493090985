









import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import {
  ProductsTeaserSourceConfigurationOptions,
  ProductsTeaserSourceOptions,
  ProductTeaserSourceMode
} from '../ProductsTeaser.contracts'
import ComponentOptions from '../../partials/ComponentOptions/ComponentOptions.mixin.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ProductsTeaserSourceForm>({
  name: 'ProductsTeaserSourceForm'
})
export class ProductsTeaserSourceForm extends Mixins(ComponentOptions) {
  @Prop({ type: Object, required: false, default: null })
  public options!: ProductsTeaserSourceOptions

  /**
   * Determines component config.
   */
  public config: ProductsTeaserSourceConfigurationOptions = this.getComponentOptions({
    modeOptions: [
      ProductTeaserSourceMode.Filters,
      ProductTeaserSourceMode.Brand,
      ProductTeaserSourceMode.BrandAndCategory,
      ProductTeaserSourceMode.Category,
      ProductTeaserSourceMode.Line
    ]
  })

  public mode: string | null = this.options?.mode ?? null

  public sku: boolean | null = this.options?.sku ?? null

  public availableModes = this.config.modeOptions.map(modeOption => {
    return {
      label: this.$t(`modulesForms.ProductsTeaser.options.${modeOption}`).toString(),
      value: modeOption
    }
  })

  @Watch('mode')
  public onModeUpdate (value: string) {
    if (value === ProductTeaserSourceMode.Filters) {
      this.$emit('update:options', { ...this.options, mode: value, sku: false })
    } else {
      this.$emit('update:options', { ...this.options, mode: value, sku: true })
    }
  }
}

export default ProductsTeaserSourceForm
