








































import { Component, Watch } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { DescriptionOfFile, PossibleRelatedPicker, Related } from '../../../contexts'

import { AbstractModuleForm } from '../../abstract/form'
import { FormAddBtn } from '../../partials/FormAddBtn'
import { Iterator } from '../../partials/Iterator'
import { ImageForm } from '../../partials/ImageForm'
import { FormFieldset } from '../../partials/FormFieldset'

import {
  WhereToBuyModule,
  WhereToBuyModuleContentInput,
  WhereToBuyModuleFilePickerOptions
} from '../WhereToBuy.contracts'
import { whereToBuyContentFactory } from '../WhereToBuy.factory'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<WhereToBuyModuleForm>({
  name: 'WhereToBuyModuleForm',
  components: { FormAddBtn, Iterator, ImageForm, FormFieldset },
  created (): void {
    if (this._content.shops && Object.keys(this._content.shops).length > 0) {
      this.selectedFile = this._content.shops
    }
  }
})
export class WhereToBuyModuleForm extends AbstractModuleForm<WhereToBuyModuleContentInput, WhereToBuyModule> {
  /**
   * @inheritDoc
   */
  protected initialContent = whereToBuyContentFactory()

  public readonly DashmixIconName = DashmixIconName

  public selectedFile: Related | null = null
  public file: DescriptionOfFile | null = null

  /**
   * Add new data to _content.brands to satisfy `<ImageForm>` contract.
   */
  public addNewBrand (): { image: Related | null; url: string } {
    return {
      image: null,
      url: ''
    }
  }

  /**
   * Determines the function to select the file
   */
  public callRelatedFileSource (): void {
    this.pickRelated(
      PossibleRelatedPicker.File,
      this.setRelatedFile,
      this.getRelatedFile(),
      WhereToBuyModuleFilePickerOptions
    )
  }

  public clearSelectedFile (): void {
    this.selectedFile = null
    this.file = null

    this._content = {
      ...this._content,
      shops: null,
      shopsData: []
    }
  }

  /**
   * Determines the description the selected file.
   */
  public async describeRelatedFile (): Promise<void> {
    if (!this.selectedFile) {
      return
    }

    this.file = await this.relatedService.describe<DescriptionOfFile>(this.selectedFile)
  }

  /**
   * Callback to get the pre-selected vale for picker.
   */
  protected getRelatedFile (): Related | undefined {
    if (!this.selectedFile) {
      return undefined
    }

    return this.selectedFile
  }

  /**
   * Callback for setting the selected related from picker.
   *
   * @param selected - returned value from picker
   */
  protected setRelatedFile (selected: Related): void {
    this.selectedFile = selected
  }

  @Watch('selectedFile')
  protected onSelectedFile (): void {
    if (this.selectedFile) {
      this._content = {
        ...this._content,
        shops: this.selectedFile
      }

      this.describeRelatedFile()
    }
  }
}

export default WhereToBuyModuleForm
