

















































import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'

import { Iterator } from '../../partials/Iterator'
import { MapSelector } from '../../partials/MapSelector'
import { FormFieldset } from '../../partials/FormFieldset'

import { TabItem, TabsModule, TabsModuleContent, TabsModuleContentInput } from '../Tabs.contracts'
import { tabsContentFactory } from '../Tabs.factory'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<TabsModuleForm>({
  name: 'TabsModuleForm',
  components: {
    Iterator,
    FormFieldset,
    MapSelector
  }
})
export class TabsModuleForm extends AbstractModuleForm<TabsModuleContentInput, TabsModule> {
  public initialContent: TabsModuleContent = tabsContentFactory()

  /**
   * Method which return new Tab to add to Tabs array.
   */
  public addNewTab (): TabItem {
    return {
      containerId: '',
      label: '',
      useAnchor: false
    }
  }
}

export default TabsModuleForm
