

















































import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'
import { FormFieldset } from '../../partials/FormFieldset'
import { HeadingForm } from '../../partials/HeadingForm'
import { ImageForm } from '../../partials/ImageForm'
import { LinkForm } from '../../partials/LinkForm'

import {
  ReviewsTeaserModule,
  ReviewsTeaserModuleContent,
  ReviewsTeaserModuleContentInput
} from '../ReviewsTeaser.contracts'
import { reviewsTeaserContentFactory } from '../ReviewsTeaser.factory'

@Component<ReviewsTeaserModuleForm>({
  name: 'ReviewsTeaserModuleForm',
  components: {
    FormFieldset,
    HeadingForm,
    ImageForm,
    LinkForm
  },
  created () {
    this.useSummaryCard = this._content.hasSummary
  }
})
export class ReviewsTeaserModuleForm extends AbstractModuleForm<ReviewsTeaserModuleContentInput, ReviewsTeaserModule> {
  /**
   * Determines initial content for ReviewsTeaser
   */
  public initialContent: ReviewsTeaserModuleContent = reviewsTeaserContentFactory()

  /**
   * Determines whether to use summary card in ReviewsTeaser
   */
  public useSummaryCard: boolean = false

  public onUseSummaryCard (): void {
    this.useSummaryCard = !this.useSummaryCard

    this._content = {
      ...this._content,
      hasSummary: this.useSummaryCard
    }
  }
}
export default ReviewsTeaserModuleForm
