




















































import { Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import {
  isRelated,
  ModuleMode,
  PossibleRelatedPicker,
  ProductData,
  Related,
  SetDescription,
  SetType
} from '../../../contexts'

import { RelatedPartial } from '../RelatedPartial'
import { HASH_PRODUCT } from './ProductForm.config'

/**
 * Component capable to handle different types of Related sets
 */
@Component<ProductForm>({
  name: 'ProductForm',
  created () {
    this.setInitialValues()
  }
})
export class ProductForm extends RelatedPartial<SetDescription> {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isSmart!: boolean

  @PropSync('product', { type: Object, required: false, default: null })
  public _product!: Related | null

  public defaultMode: ModuleMode = ModuleMode.Default
  public mode: ModuleMode = ModuleMode.Default
  public resolvedProduct: ProductData | null = null

  public get hasValue (): boolean {
    return this._product !== null && isRelated(this._product)
  }

  public get isDefaultMode (): boolean {
    return this.mode === this.defaultMode
  }

  public get isEmpty (): boolean {
    return this.resolvedProduct === null
  }

  public get isRelatedSet (): boolean {
    return this.isDefaultMode && this.hasValue
  }

  public clearSet (): void {
    this._product = null
    this.resolvedProduct = null
    this.$emit('clear')
  }

  public selectRelatedProduct (): void {
    this.pickRelated(PossibleRelatedPicker.Product, this.setRelated, this.getRelatedSource())
  }

  protected detectMode (): ModuleMode {
    if (!this.hasValue) {
      return ModuleMode.Default
    }

    return this.isSmart ? ModuleMode.Smart : ModuleMode.Default
  }

  protected getRelatedSource (): Related | undefined {
    if (!this._product) {
      return undefined
    }
    return this._product
  }

  @Watch('resolved', { deep: true })
  protected async onResolveProduct (resolvedProduct: ProductData) {
    this.resolvedProduct = resolvedProduct
  }

  protected setInitialValues (): void {
    this.mode = this.detectMode()
  }

  protected setRelated (selected: SetType): void {
    // Note! This ignore is caused by nature of computed value in Vue.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._product = selected
    this.$nextTick(() => this.resolveRelated())
  }
}

export default ProductForm
