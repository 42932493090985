






















import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'
import { v4 as uuid } from 'uuid'

import { AllowedHAlignment, CanBeAlignedHorizontally } from '../../../contexts'

import { Selectable } from '../../mixins/UseSelectable'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<HAlignmentSelector>({
  name: 'HAlignmentSelector',
  mounted () {
    if (typeof this._content.alignDesktop !== 'undefined') {
      this.forMobile = true
    }
  }
})
export class HAlignmentSelector extends Mixins<Selectable<CanBeAlignedHorizontally>>(Selectable) {
  @Prop({ type: Boolean, required: false, default: true })
  public readonly considerBreakpoints!: boolean

  protected elementId: string = 'h-alignment-selector'
  protected elementLabel: string = 'modulesForms._helpers.alignment.labelH'
  protected elementPlaceholder: string = 'modulesForms._helpers.alignment.placeholderH'

  public forMobile: boolean = false

  public get model (): CanBeAlignedHorizontally['align'] | CanBeAlignedHorizontally['alignDesktop'] {
    if (this.forMobile) {
      return this._content.alignDesktop
    }
    return this._content.align
  }

  public set model (newAlign: CanBeAlignedHorizontally['align'] | CanBeAlignedHorizontally['alignDesktop']) {
    const contentCopy = { ...this._content }

    if (this.forMobile) {
      contentCopy.alignDesktop = newAlign
    } else {
      contentCopy.align = newAlign
    }

    this._content = contentCopy
  }

  public get modelMobile (): CanBeAlignedHorizontally['align'] {
    if (!this.forMobile) {
      throw new Error('[HAlignmentSelector] Try to get wrong alignment property')
    }
    return this._content.align
  }

  public set modelMobile (newAlign: CanBeAlignedHorizontally['align']) {
    if (!this.forMobile) {
      throw new Error('[HAlignmentSelector] Try to set wrong alignment property')
    }
    const contentCopy = { ...this._content }

    contentCopy.align = newAlign
    this._content = contentCopy
  }

  public get options (): DashmixSelectItem[] {
    return Object.entries(AllowedHAlignment).map(([name, value]) => {
      return {
        label: this.$t(`modulesForms._helpers.alignment.options.${name}`).toString(),
        value
      }
    })
  }

  public get linkUUID (): string {
    return uuid().toString().substr(-6, 6)
  }

  public toggleForMobile (value: boolean): void {
    this.forMobile = value

    const content = { ...this._content }

    if (value) {
      content.alignDesktop = content.align
      content.align = undefined
    } else {
      content.align = content.alignDesktop
      content.alignDesktop = undefined
    }

    this._content = content
  }
}

export default HAlignmentSelector
