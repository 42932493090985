









import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ProductsListSourceOptions } from '../ProductsList.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ProductsListSourceForm>({
  name: 'ProductsListSourceForm'
})
export class ProductsListSourceForm extends Vue {
  @Prop({ type: Object, required: false, default: null })
  public options!: ProductsListSourceOptions

  public mode: string | null = this.options?.mode ?? null

  public availableModes = [ // todo
    {
      label: this.$t('modulesForms.ProductsList.options.filters').toString(),
      value: 'filters'
    },
    {
      label: this.$t('modulesForms.ProductsList.options.favourites').toString(),
      value: 'favourites'
    }
  ]

  @Watch('mode')
  public onUpdate (value: string) {
    this.$emit('update:options', { ...this.options, mode: value })
  }
}

export default ProductsListSourceForm
