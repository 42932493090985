













































import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'

import { ImageForm } from '../../partials/ImageForm'
import { FormFieldset } from '../../partials/FormFieldset'
import { LinkForm } from '../../partials/LinkForm'
import { IconSelect } from '../../partials/IconSelect'
import { ClassForm } from '../../partials/ClassForm'

import { ImageModule, ImageModuleContent, ImageModuleContentInput } from '../Image.contracts'
import { imageContentFactory } from '../Image.factory'

/**
 * TODO: Document all methods and properties.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<ImageModuleForm>({
  name: 'ImageModuleForm',
  components: { ClassForm, ImageForm, FormFieldset, LinkForm, IconSelect }
})
export class ImageModuleForm extends AbstractModuleForm<ImageModuleContentInput, ImageModule> {
  public initialContent: ImageModuleContent = imageContentFactory()
}

export default ImageModuleForm
