















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import * as draggable from 'vuedraggable'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<DraggableList>({
  name: 'DraggableList',
  components: { draggable: draggable.default }
})
export class DraggableList extends Vue {
  @Prop({ type: String, required: false, default: 'handle' })
  public readonly handle!: string

  @PropSync('model', { type: Array, required: true })
  public _model!: any[]

  @Prop({ type: String, required: false, default: '' })
  public readonly wrapperClass!: string
}
export default DraggableList
