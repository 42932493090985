



































































import { Component, Inject, Watch } from 'vue-property-decorator'
import throttle from 'lodash/throttle'

import { PossibleRelatedPicker } from '../../../../../contexts'

import { defaultProvider, IS_SMART_MODULES_MODE_KEY } from '../../../../../support'

import { ImageForm } from '../../../../partials/ImageForm'
import { ProductForm } from '../../../../partials/ProductForm'
import { SetForm } from '../../../../partials/SetForm'

import { Point, PointPosition } from './ImageFormWithPointsSelector.contracts'
import { POINT_LIMIT } from './ImageFormWithPointsSelector.config'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({ name: 'ImageFormWithPointsSelector', components: { ProductForm, SetForm } })
export class ImageFormWithPointsSelector extends ImageForm {
  @Inject({
    from: IS_SMART_MODULES_MODE_KEY,
    default: () => defaultProvider<boolean>(false)
  })
  public readonly isSmart!: () => boolean

  /**
   * Determines array of created points.
   */
  public createdPoints: Point[] = []

  /**
   * Determines is current user dragging point.
   */
  public dragMode: boolean = false

  /**
   * Determines related picker.
   */
  public picker = PossibleRelatedPicker

  /**
   * Determines is at least one point selected.
   */
  public get hasPoints (): boolean {
    return this.createdPoints.length > 0
  }

  /**
   * Get selected points
   */
  public get points (): Point[] {
    return this.createdPoints
  }

  /**
   * Calculate point current position.
   */
  public calculatePointPosition (e: MouseEvent): PointPosition {
    const { offsetX, offsetY } = e
    const imageWidth = (e.target as HTMLImageElement).clientWidth
    const imageHeight = (e.target as HTMLImageElement).clientHeight

    return {
      x: ((offsetX / imageWidth) * 100),
      y: ((offsetY / imageHeight) * 100)
    }
  }

  /**
   * Create point element.
   */
  public createElement (targetElement: HTMLElement, x: number, y: number): HTMLElement {
    const POINT_CLASS = 'point'
    const point = document.createElement('div')
    point.classList.add(POINT_CLASS)
    point.style.left = `${x}%`
    point.style.top = `${y}%`

    point.addEventListener('mousedown', this.onDragStart, false)
    targetElement.addEventListener('mouseup', this.onDragEnd, false)
    targetElement.addEventListener('mousemove', throttle(this.onDrag, 100), false)

    point.innerHTML =
      '  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '    <circle cx="10.5" cy="10.5" r="10" fill="#535D79" stroke="#fff" />\n' +
      '    <path d="M17.5 10.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" fill="#fff" stroke="#F6F7FA" />\n' +
      '  </svg>'

    point.innerHTML += `<span class="count">${this.createdPoints.length + 1}</span>`
    return point
  }

  /**
   * Determines current dragging element
   */
  public draggingElement (): Pick<Point, 'ref' | 'position'> | undefined {
    const foundElement = this.createdPoints.find(({ isDragging }) => !!isDragging)
    if (!foundElement) {
      return
    }

    return { ref: foundElement.ref, position: foundElement.position }
  }

  /**
   * Handle click event.
   */
  public handleImageClick (e: MouseEvent): void {
    if (this.dragMode) {
      return
    }

    if (this.createdPoints.length === POINT_LIMIT) {
      return alert(this.$t('modulesForms._helpers.imageFormWithPointsSelector.createdPointsLimit'))
    }

    const targetElement = (e.target as HTMLElement)
    const POINT_CLASS = 'point'
    if (targetElement.classList.contains(POINT_CLASS)) {
      return alert(this.$t('modulesForms._helpers.imageFormWithPointsSelector.creatingPointOnPoint'))
    }

    const position = this.calculatePointPosition(e)
    const imageWrapper = targetElement.parentElement
    const point = this.createElement(targetElement, position.x, position.y)

    if (!point || !imageWrapper) {
      return
    }

    imageWrapper.appendChild(point)
    this.createdPoints.push(
      {
        id: `point-${this.createdPoints.length}`,
        isDragging: false,
        position,
        product: null,
        ref: point
      }
    )
  }

  /**
   * Handle mousemove event.
   */
  public onDrag (e: MouseEvent) {
    const POINTER_EVENTS_NONE = 'none'
    if (!this.dragMode) {
      return
    }

    const element = this.draggingElement()
    if (!element?.ref) {
      return
    }

    const imageWrapper = element.ref.parentElement
    if (!imageWrapper) {
      return
    }

    const { offsetWidth, offsetHeight } = imageWrapper
    const { offsetX, offsetY } = e
    const x = offsetX - ((element.position.x / 100) * offsetWidth)
    const y = offsetY - ((element.position.y / 100) * offsetHeight)
    this.setTranslate(x, y, element.ref)
    element.ref.style.pointerEvents = POINTER_EVENTS_NONE
  }

  /**
   * Handle mouseup event.
   */
  public onDragEnd (e: MouseEvent) {
    const ACTIVE_CLASS = 'active'
    const CURSOR_DEFAULT = 'default'
    const POINTER_EVENTS_AUTO = 'auto'
    const TRANSFORM_UNSET = 'none'
    this.createdPoints = this.createdPoints.map((point) => {
      const { id, isDragging, product, ref } = point

      if (!isDragging || !ref) {
        return point
      }

      const position = this.calculatePointPosition(e)
      ref.style.left = `${position.x}%`
      ref.style.top = `${position.y}%`
      ref.style.pointerEvents = POINTER_EVENTS_AUTO
      ref.style.transform = TRANSFORM_UNSET
      ref.classList.remove(ACTIVE_CLASS)

      return {
        id,
        isDragging: false,
        position,
        product,
        ref
      }
    })

    setTimeout(() => {
      this.dragMode = false
    }, 0)

    const targetElement = (e.target as HTMLElement)
    const imageWrapper = targetElement.parentElement
    if (!imageWrapper) {
      return
    }

    imageWrapper.style.cursor = CURSOR_DEFAULT
  }

  /**
   * Handle mousedown event.
   */
  public onDragStart (e: MouseEvent) {
    const ACTIVE_CLASS = 'active'
    const CURSOR_MOVE = 'move'
    const targetElement = (e.target as HTMLElement)
    const imageWrapper = targetElement.parentElement
    if (!imageWrapper || !targetElement) {
      return
    }

    targetElement.classList.add(ACTIVE_CLASS)
    imageWrapper.style.cursor = CURSOR_MOVE

    this.dragMode = true
    this.createdPoints = this.createdPoints.map((point) => {
      const target = e.target as HTMLElement

      return {
        ...point,
        isDragging: target === point.ref
      }
    })
  }

  /**
   * Remove point from selected points .
   */
  public removePoint (index: number) {
    const COUNT_SELECTOR = '.count'
    const elementId = `point-${index}`
    const element = this.createdPoints.find(({ id }) => id === elementId)
    if (!element || !element.ref) {
      return
    }

    element.ref.remove()
    this.createdPoints = this.createdPoints.filter(({ id }) => id !== elementId)
      .map((point, index) => {
        point.id = `point-${index}`
        const count = point.ref?.querySelector(COUNT_SELECTOR) as HTMLElement
        if (count) {
          count.innerText = `${index + 1}`
        }

        return point
      })
  }

  public setTranslate (x: number, y: number, dragItem: HTMLElement) {
    dragItem.style.transform = `translate3d(${x}px, ${y}px, 0)`
  }

  @Watch('createdPoints', { deep: true })
  protected onPointsChange (): void {
    this.$emit('createdPoints', this.createdPoints)
  }
}

export default ImageFormWithPointsSelector
