































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { FormFieldset } from '../../../partials/FormFieldset'
import { HeadingForm } from '../../../partials/HeadingForm'
import { ImageForm, UnresolvedImage } from '../../../partials/ImageForm'
import { Iterator } from '../../../partials/Iterator'
import { LinkForm } from '../../../partials/LinkForm/LinkForm.vue'

import { AdvertiseBoxInput } from '../../AdvertiseBox.contract'
import { UnresolvedLink } from '../../../partials'
import { IRelatedService, PickerCallback, Related } from '../../../../contexts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<AdvertiseBoxItem>({
  name: 'AdvertiseBoxItem',
  components: {
    FormFieldset,
    HeadingForm,
    ImageForm,
    Iterator,
    LinkForm
  }
})
export class AdvertiseBoxItem extends Vue {
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  @PropSync('item', { type: Object, required: true })
  public _item!: AdvertiseBoxInput

  /**
   * Method which return new link to add to links array.
   */
  public addNewLink (): UnresolvedLink | null {
    return null
  }

  public updateImage (value: UnresolvedImage) {
    this._item = { ...this._item, image: value.image }
  }
}

export default AdvertiseBoxItem
