


























































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import {
  IRelatedService,
  PickerCallback
} from '../../../../contexts'
import { logger } from '../../../../support'

import { ColorSchemaSelector } from '../../../partials/ColorSchemaSelector'
import { FormFieldset } from '../../../partials/FormFieldset'
import { UnresolvedImage } from '../../../partials'
import { HeadingForm } from '../../../partials/HeadingForm'
import { ImageForm } from '../../../partials/ImageForm'
import { LinkForm } from '../../../partials/LinkForm'
import { MapSelector } from '../../../partials/MapSelector'
import { MarkdownEditor } from '../../../partials/MarkdownEditor'

import { AddonData, AddonType, PossibleAddons, SlidesInput } from '../../Hero.contracts'
import { heroModuleAddonsFormRegistry } from '../Hero.form.config'

/**
 * Single slide tab for hero module form.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<SlideTab>({
  name: 'SlideTab',
  components: { FormFieldset, HeadingForm, ImageForm, LinkForm, MapSelector, MarkdownEditor, ColorSchemaSelector },
  mounted (): void {
    this.setInitialAddonIfExists()
  }
})
export class SlideTab extends Vue {
  @PropSync('slide', { type: Object, required: true })
  public _slide!: SlidesInput

  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  /**
   * Service capable of resolving the actual data of the related objects.
   */
  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  public heroOptions = PossibleAddons

  public selectedAddon: AddonType | null = null

  /**
   * Method to get background image of slide for <ImageForm>
   */
  public get image (): UnresolvedImage | null {
    if (!Object.prototype.hasOwnProperty.call(this._slide, 'background')) {
      return null
    }

    const slide = this._slide

    if (slide.background === null) {
      return null
    }
    return {
      image: slide.background
    }
  }

  /**
   * Method to set background image of slide chosen from <ImageForm>
   */
  public set image (image: UnresolvedImage | null) {
    if (!image) {
      const slideCopy = { ...this._slide }
      slideCopy.background = null
      this._slide = slideCopy
      return
    }
    this._slide = {
      ...this._slide,
      background: image.image
    }
  }

  public get contentColor (): { color: string } | undefined {
    if (!Object.prototype.hasOwnProperty.call(this._slide, 'contentColor')) {
      return { color: 'black' }
    }

    const slide = this._slide

    if (slide.contentColor === null || typeof slide.contentColor === 'undefined') {
      return { color: 'black' }
    }

    return { color: slide.contentColor }
  }

  /**
   * Method to set background image of slide chosen from <ImageForm>
   */
  public set contentColor (contentColor: { color: string } | undefined) {
    if (!contentColor) {
      const slideCopy = { ...this._slide }
      slideCopy.contentColor = undefined
      this._slide = slideCopy
      return
    }

    this._slide = {
      ...this._slide,
      contentColor: contentColor.color
    }
  }

  /**
   * Renders selected addon's view constructor.
   */
  public get component (): VueConstructor | undefined {
    if (!this.selectedAddon) {
      return
    }

    if (!(this.selectedAddon in heroModuleAddonsFormRegistry)) {
      logger(
        'HeroModuleForm.component: Selected addon does not exists in heroModulesAddonsFormRegistry. Did you forget to register?')
      return
    }

    return heroModuleAddonsFormRegistry[this.selectedAddon]
  }

  /**
   * Adds addon selected from PossibleAddons map.
   */
  public addAddon (addon: AddonType): void {
    this.selectedAddon = addon

    switch (addon) {
      case PossibleAddons.Image:
        this._slide = {
          ...this._slide,
          addon: {
            type: this.selectedAddon,
            content: { image: null }
          }
        }
        break
      case PossibleAddons.Creations:
        this._slide = {
          ...this._slide,
          addon: {
            type: this.selectedAddon,
            content: {
              image: null,
              pointsCoordinates: []
            }
          }
        }
        break
    }
  }

  /**
   * Updates the inner content of addon
   */
  public onAddonContentChange (addonContent: AddonData['content']): void {
    if (!this.selectedAddon) {
      logger('HeroModuleForm.onAddonContentChange(): There is no selected addon! ', 'error')
      return
    }
    this._slide = {
      ...this._slide,
      addon: {
        type: this.selectedAddon,
        content: addonContent
      }
    }
  }

  /**
   * Sets initial addon if it is present.
   */
  protected setInitialAddonIfExists (): void {
    if (this._slide.addon &&
      Object.prototype.hasOwnProperty.call(this._slide.addon, 'content') &&
      Object.keys(this._slide.addon).length > 0) {
      // Here it is enough that we are assigning selectedAddon,
      // this will automatically renders dynamic component with addon content.
      // For now there is no checks for `ModuleContent`s type to be really sure that
      // this addon has its own `content`
      // Rethink how to implement it.
      this.selectedAddon = this._slide.addon.type
    }
  }
}

export default SlideTab
