



















import { Component } from 'vue-property-decorator'

import { PossibleRelatedPicker } from '../../../contexts'

import { AbstractModuleForm } from '../../abstract/form'

import { FormFieldset } from '../../partials/FormFieldset'
import { LinkForm } from '../../partials/LinkForm'
import { Iterator } from '../../partials/Iterator'
import { MarkdownEditor } from '../../partials/MarkdownEditor'
import { SetForm } from '../../partials/SetForm'

import {
  CreationsModule,
  CreationsModuleContentInput
} from '../Creations.contracts'
import { creationsContentFactory } from '../Creations.factory'

import { ImageFormWithPointsSelector } from './partials/ImageFormWithPointsSelector'
import { Point } from './partials/ImageFormWithPointsSelector/ImageFormWithPointsSelector.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<CreationsModuleForm>({
  name: 'CreationsModuleForm',
  components: { FormFieldset, ImageFormWithPointsSelector, LinkForm, Iterator, MarkdownEditor, SetForm }
})
export class CreationsModuleForm extends AbstractModuleForm<CreationsModuleContentInput, CreationsModule> {
  /**
   * Determines creations initial content.
   */
  protected initialContent = creationsContentFactory()

  /**
   * Determines related picker.
   */
  public picker = PossibleRelatedPicker

  /**
   * Determines created points.
   */
  public setCreatedPoints (createdPoints: Point[]): void {
    if (!createdPoints) {
      return
    }

    this._content.pointsCoordinates = createdPoints.map((point) => {
      return { product: point.product, x: point.position.x, y: point.position.y }
    })
  }
}

export default CreationsModuleForm
