









import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { ImageSourceOptions } from '../Image.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ImageSourceForm>({
  name: 'ImageSourceForm'
})
export class ImageSourceForm extends Vue {
  @Prop({ type: Object, required: false, default: null })
  public options!: ImageSourceOptions

  public photo: string | null = this.options?.photo ?? null

  @Watch('photo')
  public onUpdate (value: string) {
    this.$emit('update:options', { ...this.options, photo: value })
  }
}

export default ImageSourceForm
