






























































































import { Component, Prop, Watch } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'

import { ClassForm } from '../../partials/ClassForm'
import {
  DescriptionOfBaseFilter,
  PickerCallback,
  PossibleRelatedPicker
} from '../../../contexts'
import { FilterForm } from '../../partials/FilterForm'
import { FormFieldset } from '../../partials/FormFieldset'
import { HeadingForm } from '../../partials/HeadingForm'
import { Iterator } from '../../partials/Iterator'
import { SelectItem } from '../../../dsl/atoms/Select'
import { SetForm } from '../../partials/SetForm'

import {
  ProductsTeaserModule, ProductsTeaserModuleContent,
  ProductsTeaserModuleContentInput,
  ProductTeaserSourceMode
} from '../ProductsTeaser.contracts'
import { productsTeaserContentFactory } from '../ProductsTeaser.factory'
import {
  PRODUCT_TEASER_AUTO_SKU_MARKER
} from '../ProductsTeaser.config'

/**
 * Form component for the `ProductsTeaser` module.
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl> (original)
 */

@Component<ProductsTeaserModuleForm>({
  name: 'ProductsTeaserModuleForm',
  components: { ClassForm, FilterForm, FormFieldset, HeadingForm, Iterator, SetForm }
})

export class ProductsTeaserModuleForm extends AbstractModuleForm<ProductsTeaserModuleContentInput, ProductsTeaserModule> {
  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  public ProductTeaserSourceMode = ProductTeaserSourceMode

  public availableValues: Map<string, SelectItem[]> = new Map()

  /**
   * Determines products teaser initial content.
   */
  public initialContent: ProductsTeaserModuleContent = productsTeaserContentFactory()

  /**
   * Determines related picker.
   */
  public picker = PossibleRelatedPicker

  public get shouldUseSKU () {
    return this.isDynamic &&
      (this.sourceOptions.mode === ProductTeaserSourceMode.Category ||
        this.sourceOptions.mode === ProductTeaserSourceMode.Brand ||
        this.sourceOptions.mode === ProductTeaserSourceMode.BrandAndCategory || this.sourceOptions.mode === ProductTeaserSourceMode.Line)
  }

  public addFilter () {
    return { filter: null, value: [] }
  }

  public updateAvailableValues (filterValue: string, described: DescriptionOfBaseFilter) {
    const options = Object.entries(described.options).map(([value, label]) => ({ label, value }))

    this.availableValues.set(filterValue, options)
    this.availableValues = new Map(this.availableValues)
  }

  @Watch('sourceOptions')
  public onSourceOptionsChange () {
    switch (this.sourceOptions.mode) {
      case ProductTeaserSourceMode.Filters:
        this._content.sku = null
        break
      case ProductTeaserSourceMode.Brand || ProductTeaserSourceMode.Category:
        this._content.sku = PRODUCT_TEASER_AUTO_SKU_MARKER
        break
    }
  }

  @Watch('isDynamic')
  public onIsDynamicChange () {
    if (this.isDynamic) {
      this.sourceOptions.mode = ProductTeaserSourceMode.Filters
    } else {
      this.sourceOptions.mode = null
      this._content.sku = null
    }
  }
}

export default ProductsTeaserModuleForm
