







import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'

import { DescriptionListModule, DescriptionListModuleContent, DescriptionListModuleContentInput } from '../DescriptionList.contracts'
import { descriptionListContentFactory } from '../DescriptionList.factory'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<DescriptionListModuleForm>({
  name: 'DescriptionListModuleForm'
})
export class DescriptionListModuleForm extends AbstractModuleForm<DescriptionListModuleContentInput, DescriptionListModule> {
  public initialContent: DescriptionListModuleContent = descriptionListContentFactory()
}

export default DescriptionListModuleForm
