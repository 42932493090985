






















import { v4 as uuid } from 'uuid'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { AllowedVAlignment, CanBeAlignedVertically } from '../../../contexts'

import { Selectable } from '../../mixins/UseSelectable'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'VAlignmentSelector'
})
export class VAlignmentSelector extends Mixins<Selectable<CanBeAlignedVertically>>(Selectable) {
  @Prop({ type: Boolean, required: false, default: true })
  public readonly considerBreakpoints!: boolean

  protected elementId: string = 'v-alignment-selector'
  protected elementLabel: string = 'modulesForms._helpers.alignment.labelV'
  protected elementPlaceholder: string = 'modulesForms._helpers.alignment.placeholderV'

  public forMobile: boolean = false

  public get model (): CanBeAlignedVertically['align'] | CanBeAlignedVertically['alignDesktop'] {
    if (this.forMobile) {
      return this._content.alignDesktop
    }
    return this._content.align
  }

  public set model (newAlign: CanBeAlignedVertically['align'] | CanBeAlignedVertically['alignDesktop']) {
    const contentCopy = { ...this._content }

    if (this.forMobile) {
      contentCopy.alignDesktop = newAlign
    } else {
      contentCopy.align = newAlign
    }

    this._content = contentCopy
  }

  public get modelMobile (): CanBeAlignedVertically['align'] {
    if (!this.forMobile) {
      throw new Error('[VAlignmentSelector] Try to get wrong alignment property')
    }
    return this._content.align
  }

  public set modelMobile (newAlign: CanBeAlignedVertically['align']) {
    if (!this.forMobile) {
      throw new Error('[VAlignmentSelector] Try to set wrong alignment property')
    }
    const contentCopy = { ...this._content }

    contentCopy.align = newAlign
    this._content = contentCopy
  }

  public get options (): DashmixSelectItem[] {
    return Object.entries(AllowedVAlignment).map(([name, value]) => {
      return {
        label: this.$t(`modulesForms._helpers.alignment.options.${name}`).toString(),
        value
      }
    })
  }

  public get linkUUID (): string {
    return uuid().toString().substr(-6, 6)
  }

  public toggleForMobile (value: boolean): void {
    this.forMobile = value

    const content = { ...this._content }

    if (this.forMobile) {
      content.alignDesktop = content.align
      content.align = undefined
    } else {
      content.align = content.alignDesktop
      content.alignDesktop = undefined
    }

    this._content = content
  }
}

export default VAlignmentSelector
