










import { Component, Prop, Vue } from 'vue-property-decorator'

import { DashmixIconName } from '@movecloser/ui-core'

@Component({
  name: 'ItemTabLabel'
})
export class ItemTabLabel extends Vue {
  @Prop({ type: Number, required: true })
  public readonly tabIndex!: number

  @Prop({ type: String, required: false, default: 'Slide 1' })
  public readonly label!: string

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isActive!: boolean

  @Prop({ type: Function, required: true })
  private onItemRemove!: (index: number) => void

  public icon = DashmixIconName

  public itemRemove (): void {
    this.onItemRemove(this.tabIndex)
  }
}
export default ItemTabLabel
