









import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { DescriptionListSourceOptions } from '../DescriptionList.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<DescriptionListSourceForm>({
  name: 'DescriptionListSourceForm'
})
export class DescriptionListSourceForm extends Vue {
  @Prop({ type: Object, required: false, default: null })
  public options!: DescriptionListSourceOptions

  public availableMarkers = [ // todo
    {
      label: 'Atrybuty produktu',
      value: 'product_attributes'
    }
  ]

  public marker: string | null = this.options?.marker ?? this.availableMarkers[0].value

  @Watch('marker')
  public onUpdate (value: string) {
    this.$emit('update:options', { ...this.options, marker: value })
  }
}

export default DescriptionListSourceForm
