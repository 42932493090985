














import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { IRelatedService, PickerCallback } from '../../../../contexts'

import { CategoryForm } from '../../../partials/CategoryForm'
import { FormFieldset } from '../../../partials/FormFieldset'

import { UnresolvedBrandData } from '../../BrandTeaser.contracts'

/**
 * Single brand tab for brand teaser module form.
 *
 * @author @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<BrandTab>({
  name: 'BrandTab',
  components: {
    CategoryForm,
    FormFieldset
  }
})
export class BrandTab extends Vue {
  @PropSync(
    'slide',
    {
      type: Object,
      required: true
    }
  )
  public _brand!: UnresolvedBrandData

  /**
   * Callback used by form to pick relate.
   */
  @Prop({
    type: Function,
    required: false
  })
  public readonly pickRelated!: PickerCallback

  /**
   * Service capable of resolving the actual data of the related objects.
   */
  @Prop({
    type: Object,
    required: true
  })
  public readonly relatedService!: IRelatedService
}

export default BrandTab
