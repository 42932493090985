






import { Component } from 'vue-property-decorator'

import CreationsModuleForm from '../../../Creations/form/Creations.form.vue'

import { AbstractHeroAddon } from './abstract'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'CreationAddon', components: { CreationsModuleForm } })
export class CreationAddon extends AbstractHeroAddon {}
export default CreationAddon
