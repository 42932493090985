










import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

/**
 * `Tab Label` VueComponent for modules with slides
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({
  name: 'BrandTabLabel'
})
export class BrandTabLabel extends Vue {
  @Prop({ type: Number, required: true })
  public readonly tabIndex!: number

  @Prop({ type: String, required: false, default: 'Slide 1' })
  public readonly label!: string

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isActive!: boolean

  @Prop({ type: Function, required: true })
  private onSlideRemove!: (index: number) => void

  public icon = DashmixIconName

  public slideRemove (): void {
    this.onSlideRemove(this.tabIndex)
  }
}
export default BrandTabLabel
