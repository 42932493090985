



































































import { Component } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { AbstractModuleForm } from '../../abstract/form'

import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm } from '../../partials/ImageForm'
import { MarkdownEditor } from '../../partials/MarkdownEditor'
import { LinkForm } from '../../partials/LinkForm'

import { CTABoxModule, CTABoxModuleContentInput } from '../CTABox.contracts'
import { ctaBoxContentFactory } from '../CTABox.factory'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CTABoxModuleForm>({
  name: 'CTABoxModuleForm',
  components: { ImageForm, FormFieldset, MarkdownEditor, LinkForm }
})
export class CTABoxModuleForm extends AbstractModuleForm<CTABoxModuleContentInput, CTABoxModule> {
  protected initialContent = ctaBoxContentFactory()

  public get directionOptions (): DashmixSelectItem[] {
    return ['right', 'left'].map((direction) => {
      return {
        label: String(this.$t(`modulesForms.CTABox.direction.options.${direction}`)),
        value: direction
      }
    })
  }

  public get hasCTA (): boolean {
    return typeof this._content.cta !== 'undefined'
  }

  public set hasCTA (value: boolean) {
    const contentCopy = { ...this._content }
    if (!value) {
      contentCopy.cta = undefined
    } else {
      contentCopy.cta = {
        action: null
      }
    }

    this._content = contentCopy
  }
}

export default CTABoxModuleForm
