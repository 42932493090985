// Copyright © 2022 Move Closer

import { Component, PropSync, Vue } from 'vue-property-decorator'

import { VideoModuleContentInput } from '../../Video.contracts'

/**
 * Abstract video form
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({
  name: 'EmbedVersionAbstractForm'
})
export class EmbedVersionAbstractForm extends Vue {
  /**
   * Content of `EmbedModule`
   */
  @PropSync('content', { type: Object, required: false, default: () => ({}) })
  public _content!: VideoModuleContentInput

  /**
   * Override this expression with appropriate version's expression
   * @protected
   */
  protected readonly regex: RegExp = /\$/
}

export default EmbedVersionAbstractForm
