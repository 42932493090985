














import { DashmixBoxTabItem, DashmixIconName, IFile, ImageType } from '@movecloser/ui-core'
import { Component, Prop } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import {
  IRelatedService,
  ModuleContent,
  PickerCallback,
  Related
} from '../../../contexts'

import { AvailableModuleDriver } from '../../allowed'
import { FormFieldset } from '../../partials/FormFieldset'

import {
  VideoModule,
  VideoModuleContentInput,
  VideoVersion
} from '../Video.contracts'

import { YouTubePartialForm } from './versions'
import { AbstractModuleForm } from '../../abstract/form'

/**
 * Presentational component for the `VideoModuleForm`.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<VideoModuleForm>({
  name: 'VideoModuleForm',
  components: { FormFieldset },
  mounted (): void {
    this.$nextTick(() => {
      switch (this._version) {
        case VideoVersion.YouTube:
          if (typeof this._content.autoplay === 'undefined') {
            this.toggleAutoplay(true)
          }
          this.findImageRelated()
          break
      }
    })
  }
})
export class VideoModuleForm extends AbstractModuleForm<VideoModuleContentInput, VideoModule> {
  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  /**
   * Service capable of resolving the actual data of the related objects.
   */
  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  /**
   * This property is responsible for disabling tabs if EmbedToken in MDE is editing
   *
   * @private
   */
  @Prop({ type: Boolean, required: false, default: false })
  private isEditMode!: boolean

  public readonly DashmixIconName = DashmixIconName

  protected initialContent: VideoModuleContentInput = VideoModuleForm.getInitialContent()

  /**
   * Title of the image.
   */
  public imgTitle: string = ''

  /**
   * Image.
   */
  public thumbnail: IFile | null = null

  /**
   * Active tab according to `_version`
   */
  public activeTab: string = 'youtube'

  /**
   * Tabs
   *
   * @see DashmixBoxTabItem
   */
  public get embedOptions (): DashmixBoxTabItem[] {
    return [
      {
        component: YouTubePartialForm,
        props: {
          content: this._content,
          imgTitle: this.imgTitle,
          onAutoplayToggle: this.toggleAutoplay,
          onSelection: this.setThumbnailSource,
          onRemove: this.removeThumbnail,
          onVideoIdChange: this.onVideoIdChange,
          thumbnail: this.thumbnail,
          pickRelated: this.pickRelated,
          relatedService: this.relatedService
        },
        tab: {
          id: 'youtube',
          disabled: this.isTabDisabled,
          label: 'YouTube'
        }
      }
    ]
  }

  /**
   * Check whether module is editing and disable tab
   */
  public get isTabDisabled (): boolean {
    return (this.isEditMode && this._content.version === this._version)
  }

  /**
   * @inheritDoc
   * @override
   */
  public static getInitialConfig (): ModuleContent {
    return {
      driver: AvailableModuleDriver.Video,
      size: {
        colSpan: 2,
        rowSpan: 2,
        isResizable: true,
        minColSpan: 1,
        minRowSpan: 1,
        maxColSpan: 6
      },
      version: VideoVersion.YouTube
    }
  }

  /**
   * @inheritDoc
   * @override
   */
  public static getInitialContent (): VideoModuleContentInput {
    return {
      autoplay: false,
      description: '',
      thumbnail: null
    }
  }

  public isPromise (obj: any) {
    return obj instanceof Promise
  }

  public removeThumbnail (): void {
    this._content.thumbnail = {} as Related
  }

  public onVideoIdChange (id: string) {
    this._content = {
      ...this._content,
      videoId: id
    }
  }

  public toggleAutoplay (auto: boolean): void {
    const content = this._content
    content.autoplay = auto
    this._content = content
  }

  protected getImageFromRelated (related: AnyObject) {
    if (Object.prototype.hasOwnProperty.call(related, 'alt')) {
      this.imgTitle = related.title
    }

    if (Object.prototype.hasOwnProperty.call(related, 'url')) {
      this.thumbnail = {
        type: ImageType.JPG,
        thumbnail: related.url
      }

      if (Object.prototype.hasOwnProperty.call(related, 'mime')) {
        this.thumbnail.type = related.mime
      }
    }
  }

  protected findImageRelated () {
    if (!this._content.thumbnail || typeof this._content.thumbnail.value === 'undefined' ||
      typeof this._content.thumbnail.type === 'undefined') {
      return
    }
    const related = this.relatedService.resolve(this._content.thumbnail, {})

    if (!this.isPromise(related)) {
      this.getImageFromRelated(related)
      return
    }

    related.then((related: AnyObject) => {
      this.getImageFromRelated(related)
    }).catch(error => {
      console.debug(error)
    })
  }

  protected setThumbnailSource (payload: AnyObject): void {
    if (!payload || !payload.hasOwnProperty('image')) {
      return
    }

    this._content.thumbnail = payload.image

    this.$nextTick(() => {
      this.findImageRelated()
    })
  }
}

export default VideoModuleForm
