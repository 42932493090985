






import { Component } from 'vue-property-decorator'

import { ImageForm } from '../../../partials/ImageForm'

import { AbstractHeroAddon } from './abstract'

/**
 * **IMAGE ADDON** for Hero module.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'ImageAddon', components: { ImageForm } })
export class ImageAddon extends AbstractHeroAddon {}
export default ImageAddon
