



















import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'

import { FormFieldset } from '../../partials/FormFieldset'
import { LinkForm } from '../../partials/LinkForm'

import { newsletterContentFactory } from '../Newsletter.factory'
import {
  NewsletterModule,
  NewsletterModuleContent,
  NewsletterModuleContentInput
} from '../Newsletter.contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component({ name: 'NewsletterModuleForm', components: { FormFieldset, LinkForm } })
export class NewsletterModuleForm extends AbstractModuleForm<NewsletterModuleContentInput, NewsletterModule> {
  public initialContent: NewsletterModuleContent = newsletterContentFactory()
}

export default NewsletterModuleForm
